<template>
    <div class="magento-code-review-page" @scroll="scrolling()">
        <breadcrumbs
            :breadcrumbs="breadcrumbs"
            :scroll="scroll"
            @goBack="back"
        />
        <section class="magento-code-review__banner magento-code-review__banner--eclipse">
            <img
                class="magento-code-review__banner-img"
                src="@/assets/blog/magento-code-review/magento-code-review.jpg"
                alt="Custom home delivery date & time solution for ecommerce"
                loading="lazy"
                modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
            <h1 class="magento-code-review__banner-title">
                THE ULTIMATE GUIDE TO MAGENTO CODE REVIEW: CRUCIAL STEPS TO E-COMMERCE SUCCESS
            </h1>
        </section>
        <div class="container magento-code-review section_holder">
            <div class="section_title">
                <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
            </div>
            <div class="section_content">
                <div class="section_content__item">
                    <p>
                        Our <a class="link" href="/blog/code-review">previous article</a> outlined how you can benefit from a timely code review of your website. This time we will focus on Magento Code Audit as it is one of the most popular and UX-friendly platforms for e-commerce businesses today. As Magento experts with 15+ years of successfully working with online merchants worldwide, the WDEVS team has been conducting custom Magento code audits, delving deep into clients’ Magento stores to discover and prevent any bugs, security hacks, outdated code, or performance issues to ensure that a website is stable, scalable, and secure for company customers’ use.
                    </p>
                    <p>
                        Of course, each release of Magento by its vendor - Adobe - is definitely high-quality software by itself. But in the fast-paced realm of online commerce, where businesses strive to outshine the competition and deliver impeccable customer experience, many merchants customize and personalize their online selling platforms. Needless to say, any customizations, extensions, or other changes added on the client side may require a code review.
                    </p>
                    <p>
                        Let’s take this example: the main programming language for the Magento e-commerce platform is PHP. So most of the 3rd-party Magento extensions or other customizations are based on PHP. One of the quality standards for PHP coding is PSR-2 (<a href="https://www.php-fig.org/psr/psr-2/">https://www.php-fig.org/psr/psr-2/</a>). It means that all the core code should comply with PSR-2 policies.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="magento-code-review__img">
                        <img
                            src="@/assets/blog/magento-code-review/code-analysis.jpg"
                            alt="Code review for Magento stores"
                            title="Code review"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        What role does code review play for Magento stores?
                    </h2>
                    <p>
                        Magento has developed to be one of the most popular and sought-after e-commerce platforms today due to its distinctive features and user-friendly interface. It is currently in use by more than 150k live websites. Nevertheless, we often see that, in reality, Magento extensions or custom solutions get released without corresponding quality assurance, which may impact their effectiveness. Usually, it would not take long before this results in a significant drop in sales. The most common reason? Interface errors and lack of functionality on your site showing that your website does not work as expected.
                    </p>
                    <p>
                        We thought it could be best demonstrated by the following case study. One of our clients reached us for a code audit after some initial website customization by another web development team. It happened after several customers complained about problems processing their orders online.
                    </p>
                    <p>
                        A code audit revealed that one of the extensions (which provides particular features to the product page) had a bug - if a specific number of products was added to the cart, the shopping cart got emptied (see the images below). Thus, poor software quality led to direct business losses.
                    </p>

                    <div class="section_content__item-slider">
                        <agile
                            ref="carousel"
                            class=""
                            :nav-buttons="true"
                            :dots="false"
                            :speed="2500"
                            :initial-slide="0"
                            :options="myOptions"
                        >
                            <div style="text-align: center">
                                <img src="../../assets/blog/magento-code-review/slider-image-01.png" alt="Slider image 2" title="Slider image 1" />
                            </div>
                            <div style="text-align: center">
                                <img src="../../assets/blog/magento-code-review/slider-image-02.png" alt="Slider image 2" title="Slider image 2" />
                            </div>
                        </agile>
                        <button
                            class="agile__nav-button agile__nav-button--prev"
                            @click.passive="$refs.carousel.goToPrev()"
                            aria-label="prev button"
                        >
                            <img
                                v-svg-inline
                                :data-src="require('@/assets/icons/white/prev.svg')"
                                alt=""
                            />
                        </button>
                        <button
                            class="agile__nav-button agile__nav-button--next"
                            @click.passive="$refs.carousel.goToNext()"
                            aria-label="next button"
                        >
                            <img
                                v-svg-inline
                                :data-src="require('@/assets/icons/white/next.svg')"
                                alt=""
                            />
                        </button>
                    </div>
                    <p>
                        <i>In the example above, you can also notice an incorrect product name displayed in the shopping cart page block. A common problem that can affect your customer satisfaction with the shopping process.</i>
                    </p>

                    <p>
                        The reason behind wrong product data and product name spelling is usually incorrect text data parsing, which may lead users to doubt whether to proceed with buying from your website. So, a small mistake can lead to significant financial and reputational losses.
                    </p>
                    <p>
                        Moreover, keep in mind that Magento, as any software, gets constantly updated, with Adobe releasing new versions each 3-5 months. Every new Magento version features different modifications and improvements based on ever-evolving industry standards, security requirements, and programming language version updates. For example, Magento 2.4.3, released in August 2021, requires PHP 7.4, while Magento 2.4.5, released in August 2022, requires PHP 8.1, which is changed considerably from previous versions, missing deprecated inefficient functions but including, for example, higher security standards.
                    </p>
                    <p>
                        Magento’s robust functionality allows you to add numerous third-party integrations and customizations to suit your web store’s unique needs. But that also means higher levels of site architecture complexity and potential bug risks. You might not be aware of these issues until it’s too late when your sales drop . We recommend taking a quick look at your clients' feedback about your website and carrying out a preliminary performance audit. These are a few signs your website needs a thorough code review:
                    </p>
                    <ul>
                        <li>Poor page loading speed</li>
                        <li>Broken links</li>
                        <li>Wrong product data or incorrect layout display</li>
                        <li>Website crashes</li>
                        <li>High server loads</li>
                        <li>Disabled full-page cache</li>
                        <li>Double processing orders, payment transaction errors</li>
                        <li>Displaying error messages</li>
                        <li>Running on an outdated Magento version</li>
                    </ul>
                    <p>
                        As soon as you realize any of those signs, we recommend you conduct a Magento code audit to minimize possible risks and ensure your website streamlined performance.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="magento-code-review__img">
                        <img
                            src="@/assets/blog/magento-code-review/code-reviewer.jpg"
                            alt="Code reviewer for Magento stores"
                            title="code reviewer"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        Who performs the code review?
                    </h2>
                    <p>
                        When it comes to the Magento user interface code review, the experienced frontend developer together with the skilled QA engineer is your best team to do this work. The specifics of user interface code review allow, in some cases, to complete tasks without sharing any delicate information like access credentials, for example, since the user interface is public. This means you can easily delegate this work to a professional outsourcing web development company with no risk to confidentiality.
                    </p>
                    <p>
                        Reviewing Magento extensions and customizations code, or the database structure is the job for an experienced backend developer, who follows the respective coding standards applicable to the existing Magento version, as well as best practices and recommendations. Again, bringing a qualified QA engineer to a team would be a good idea too.
                    </p>
                    <p>
                        And there’s the Magento Server performance. This falls on the system administrator or DevOps engineer's shoulders. It’s important to mention that Magento is connected to the Server (like the Pages tool belongs to your macOS or Word runs on your Windows); thus, the Server needs to be set up correctly, well-configured, and maintained regularly to secure the best performance. Server software management is a huge topic, so we are going to share some useful tips about it in a separate blog article next time.
                    </p>
                    <p>
                        WDEVS has a team of experienced Magento developers who know the ins and outs of the platform. We identify areas for improvement for your codebase and make recommendations for addressing them. Our Magento code audit services help you improve the quality of your code and the security of your website.
                    </p>
                </div>
                <div class="section_content__item">
                    <div class="magento-code-review__img">
                        <img
                            src="@/assets/blog/magento-code-review/programmer-and-coding-concept.jpg"
                            alt="How do we perform the code review?"
                            title="How do we perform the code review?"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <h2 class="section_content__item-title">
                        How do we perform the code review?
                    </h2>
                    <p>
                        Considering that today's Magento software and its extensions have thousands of lines of code - it’s not realistically possible to perform a good code review manually. This is where another software comes in, designed specifically to analyze the code and alert the issues.
                    </p>
                    <p>
                        For example, here’s the result of the above-mentioned automated check of a script file at GitLab:
                    </p>
                    <div class="magento-code-review__img">
                        <img
                            src="@/assets/blog/magento-code-review/screen-img-01.jpg"
                            alt="First case"
                            title="First case"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <p>
                        Another case is when there’s an identified functionality problem; in this case, our developer immediately knows where to look and what to research to find the reason and propose a quick solution. Here’s an example of a Magento issue found and a recommendation provided:
                    </p>
                    <div class="magento-code-review__img">
                        <img
                            src="@/assets/blog/magento-code-review/screen-img-02.jpg"
                            alt="Second case"
                            title="Second case"
                            loading="lazy"
                            modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
                        />
                    </div>
                    <p>
                        All issues found get recorded, and a report is formed with recommendations and preliminary estimates for the development work to fix all the problems found. For example, in this particular case, we fixed the existing bugs left by the previous dev team, made additional adjustments, integrated new features, and provided ongoing support.
                    </p>
                    <p>
                        Our code auditing process involves a comprehensive and systematic review of each line of code in your codebase. The goal is to identify all the potential issues, vulnerabilities, or areas of improvement to ensure a secure and high-performing website. We follow the most up-to-date international standards and programming practices, that allow your code to:
                    </p>
                    <ul>
                        <li>Be bug-free;</li>
                        <li>Serve the purpose;</li>
                        <li>Have readable formatting;</li>
                        <li>Ensure stable functionality;</li>
                        <li>Be adaptable to future changes and improvements;</li>
                        <li>Have necessary comments for other developers to understand and work with it;</li>
                        <li>Be compliant with the current Magento version standards.</li>
                    </ul>
                    <p>
                        Usually the Magento code audit needs to be conducted once or twice a year. The more frequently you do the audit, the more scalable and secure your website is. As for the time dedicated to completing the audit, it depends on your e-commerce platform size, integrated modules and extensions, implemented custom features and the frequency of website updates.
                    </p>
                    <p>
                        During a standard code review procedure, we will investigate the following:
                    </p>
                    <p>
                        As a US-based outsourcing web development company with over 15 years of Magento experience, the WDEVS team has established long-term partnerships with many leading e-commerce merchants worldwide.  We have a proven track of reliability and high-rated support within the Magento user community.
                    </p>
                    <p>
                        <b>With our extensive expertise in Magento and other e-commerce products we are proud to provide unparalleled value to our customers. If you want to enhance your website performance and functionality by carrying out a rigorous and accessible code review, don’t hesitate to contact us for a free consultation today.</b>
                    </p>
                </div>
                <div>
                    <div class="footer-blog-item">
                        <div
                            class="btn-read-more primary contacts-button small"
                            id="show-modal"
                            @click="this.$router.push('/blog')"
                        >
                            <span class="button-content">
                                <span class="icon"
                                ><img
                                    v-svg-inline
                                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                                    alt=""
                                /></span>
                                Read more our stories
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";
import {VueAgile} from "vue-agile";

export default {
    name: "BlogMagentoCodeReview",
    agile: VueAgile,
    data() {
        return {
            backHistory:history.state.back,
            showModal:false,
            tags:['Healthcare','Delivery','Calendar','Schedule','Delivery date'],
            scroll: false,
            position: null,
            text: `
              Would you like to receive more examples of what we are able to help you achieve?<br />
              Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
              Would you like us to propose ways of turning your ideas into reality?
            `,
            breadcrumbs: [
                {
                    link: '/',
                    name: 'Home',
                },
                {
                    link: '/blog',
                    name: 'Blog',
                },
                {
                    name: 'Ultimate guide magento code review',
                },
            ],
            myOptions: {
                dots: false,
                navButtons: false,
                responsive: [
                    {
                        breakpoint: 320,
                        settings: {
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            dots: false,
                        },
                    },
                ],
            },
        };
    },
    components: {
        SidebarBlog,
        Breadcrumbs,
    },
    computed:{
        ...mapState(['blog'])
    },
    methods: {
        goToPath(tag){
            let post = this.blog.find(item => item.tags.includes(tag))
            this.$router.push(post.img_src)
        },
        back(){
            if(!document.referrer || this.backHistory !== '/blog'){
                Object.defineProperty(document, "referrer", {
                    get: function () {
                        return `${window.location.origin}/blog`;
                    },
                });
                this.$router.push('/blog')
                return
            } else{
                this.$router.go(-1)
            }
        },
        scrolling() {
            const scroll = document.documentElement.scrollTop;
            if (scroll > 50) {
                this.scroll = true;
            } else {
                this.scroll = false;
            }
        },
    },
    created() {
        console.log(history.state.back)
        // history.pushState('blog-home',window.location.href)
        window.history.scrollRestoration = "auto";
        window.addEventListener("scroll", this.scrolling);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.scrolling);
    },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.magento-code-review-header {
    .magento-code-review__title {
        padding: 120px 0;
        text-align: end;
        text-transform: uppercase;
        color: $black;
        font-size: 32px;
        line-height: 111%;
        margin: 0;
        @include media(">=tablet") {
            font-size: 40px;
            max-width: 100%;
        }
        @include media(">=tablet-xl") {
            font-size: 56px;
        }
        @include media(">=widescreen") {
            font-size: 72px;
            max-width: 100%;
        }
    }
}

.footer-blog-item {
    display: block;
    padding-top: 20px;
    @include media(">=tablet") {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .magento-code-review-tags {
        margin-top: 40px;
        color: $red;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        @include media(">=tablet") {
            margin-top: 0;
            text-align: center;
        }
        span {
            margin-right: 20px;
            @include media(">=widescreen") {
                margin-right: 40px;
            }
        }
    }
}

.magento-code-review-page {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .link{
        color:$red;
        cursor:pointer;
        &:hover{
            text-decoration: underline;
        }
    }
    .btn-read-more {
        .button-content {
            border-radius: 26px;
            padding: 2px 12px 3px;
            background: $red;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            cursor: pointer;
            width: max-content;
            background-image: linear-gradient($darkRed, $darkRed);
            background-repeat: no-repeat;
            transition: background-size 0s, color 0s;
            background-size: 0% 100%;
            @include media(">=tablet-xl") {
                padding: 6px 24px 8px;
            }
            &:hover {
                background-size: 100% 100%;
                color: $white;
            }
        }
        .icon {
            padding-top: 6px;
            margin-right: 15px;
            transform: rotateY(180deg);
        }
        a {
            color: $white;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .go-back-to-read {
        padding: 16px 0;
        border-bottom-left-radius: 26px 26px;
        border-bottom-right-radius: 26px 26px;
        position: fixed;
        background: $white;
        z-index: 50;
        width: 100vw;
        transition: all 0.3s;
        @include media(">=tablet-xl") {
            padding: 16px 0;
        }
        &.scrolling {
            padding: 5px 0;
            border-bottom: 1px solid $red;
            @include media(">=tablet-xl") {
                padding: 5px 0;
            }
        }
    }
}
.magento-code-review {
    &.section_holder {
        display: flex;
        flex-direction: column-reverse;
        @include media(">=tablet-xl") {
            flex-direction: row;
        }
    }
    .section_title {
        margin-right: 0;
        width: 100%;
        @include media(">=tablet-xl") {
            margin-right: 43px;
            width: 35%;
        }
    }
    .section_content {
        margin-bottom: 60px;
        display: block;
        margin-left: 0;
        width: 100%;
        a {
            color: $red;
        }
        &__item {
            margin-bottom: 40px;
            @include media(">=tablet") {
                margin-bottom: 60px;
            }
            @include media(">=desktop") {
                margin-bottom: 120px;
            }
            &-title {
                &-icon {
                    flex-shrink: 0;
                    width: 400px;
                    height: auto;
                    margin-right: 15px;
                    @media all and (max-width: 480px) {
                        width: 100%;
                        margin-right: 0;
                    }
                    svg {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
                &--icon {
                    display: flex;
                    align-items: flex-end;
                    @media all and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    @media (min-width: 992px) and (max-width: 1199px) {
                        flex-wrap: wrap;
                    }
                }
            }
            &-slider {
                position: relative;
                margin: 0 0 1em;
            }
        }
        @include media(">=tablet-xl") {
            margin-left: 37px;
            width: 65%;
            margin-bottom: 0;
        }
        &__paragraph {
            display: flex;
            margin-bottom: 45px;
            &-icon {
                flex-shrink: 0;
                display: inline-block;
                width: 65px;
                height: 65px;
                margin-right: 10px;
                vertical-align: middle;
                @media all and (min-width: 768px) {
                    width: 100px;
                    height: 100px;
                    margin-right: 15px;
                }
                svg {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.magento-code-review__banner {
    height: 300px;
    position: relative;
    margin-bottom: 60px;
    @include media(">=tablet") {
        height: 500px;
    }
    @include media(">=desktop") {
        height: 600px;
        margin-bottom: 120px;
    }
    &--eclipse {
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.3);
        }
        .magento-code-review__banner-title {
            z-index: 3;
        }
    }
    &-img,
    picture {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.magento-code-review__banner-title {
    text-transform: uppercase;
    color: $white;
    font-size: 32px;
    line-height: 111%;
    position: absolute;
    bottom: 20px;
    left: 16px;
    max-width: calc(100% - 32px);
    margin: 0;
    @include media(">=tablet") {
        bottom: 40px;
        font-size: 40px;
        left: 40px;
        max-width: calc(100% - 80px);
    }
    @include media(">=tablet-xl") {
        bottom: 80px;
        left: 40px;
        font-size: 56px;
        max-width: calc(100% - 80px);
    }
    @include media(">=widescreen") {
        bottom: 80px;
        left: 80px;
        font-size: 72px;
        max-width: 1150px;
    }
}
.magento-code-review__img,
picture {
    margin: 0 0 40px;
    display: block;
}
.magento-code-review__customer {
    em {
        display: block;
        margin-top: 32px;
    }
    .button {
        margin-top: 40px;
    }
}
</style>
